// packages
import React from "react";
import Link from "next/link";
import { QueryClient } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import isEqual from "react-fast-compare";
import Cookies from "js-cookie";
import { DateTime } from "luxon";

// components
import SiteHeader from "@/shared/components/SiteHeader";
import { PolicyEditor } from "@/quote-ptz-us/src/components/PolicyEditor";
import { UserContactEditor } from "@/shared/components/UserContactEditor";
import { CoverageEditor } from "@/shared/components/CoverageEditor";
import { BillingEditor } from "@/quote-ptz-us/src/components/BillingEditor";
import SiteFooter from "@/shared/components/SiteFooter";
import FooterContent from "./src/components/FooterContent";
import GiftCardDisclaimer from "@/quote-ptz-us/src/components/GiftCardDiscaimer";
import { CustomizationSlot } from "@/shared/components/CustomizationSlot";
import Exclusions from "@/quote-ptz-us/src/components/Exclusions";
import { PriceInfo } from "@/shared/components/PriceInfo";
import { BillingOutage } from "@/shared/components/BillingOutage";
import modalContent from "@/quote-ptz-us/src/components/FullCoverageDetailsModal";
import { FreePetDisclaimer } from "@/shared/components/FreePetDisclaimer";
import { ContactDisclaimer } from "@/quote-ptz-us/src/components/ContactDisclaimer";

// context
import { AppStateInterface } from "@/shared/contexts/AppLayer";

// config
import { PublicConfig } from "@/shared/PublicConfig";

// utils
import { CoverageUtils } from "@/shared/utils/CoverageUtils";
import { PtzUsDataUtils } from "@/quote-ptz-us/src/utils/PtzUsDataUtils";
import { QuoteDataUtils } from "@/shared/utils/QuoteDataUtils";
import { QuoteAPI } from "@/shared/utils/QuoteAPI";
import Strings from "@/shared/utils/Strings.constants";
import { PresetCoverageLevel } from "@/shared/utils/CoverageUtils";
import { TrackingUtils } from "@/shared/utils/TrackingUtils";
import { TOP_BREEDS, US_FOOTER_LINKS } from "@/shared/utils/constants";
import { UIUtils } from "@/shared/utils/UIUtils";
import { BuilderUtils } from "@/shared/utils/BuilderUtils";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";

// media
import { SpotLogo } from "@/shared/media/SpotLogo";

// types
import { FormConfig, FormStep } from "@/shared/types/Form";
import { UsPetPoliciesSchema, UsPolicyStepSchema } from "@/quote-ptz-us/src/schema/PtzUsQuote";
import { PartialQuoteSchema, BillingStepSchema, QuoteFinalized, PolicySchema, Quote, CoverageSettings, ExtendedBillingStepSchema } from "@/shared/types/Quote.interface";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { SpotErrorMessage } from "@/shared/types/SpotAPI";
import { SubmitHandler, UseFormSetValue } from "react-hook-form";
import { StepperConfig } from "@/shared/components/Stepper";
import { SAMPLE_POLICY_URL } from "./src/utils/constants";
import { StripeProvider } from "@/shared/contexts/StripeProvider";
import { EmailUtils } from "@/shared/utils/EmailUtils";
import { StatsigNameFields } from "@/shared/types/Statsig";
import { UsQuoteFormStepIds, UsQuoteFormSteps } from "./formConfigTypes";
import { Badge, Heading } from "@/shared/components/ui";

type UsQuoteFormProps = {
    underwriter: PetUnderwriterType;
    updateAppState: (state: Partial<AppStateInterface>) => void;
    updateQuote?: UseFormSetValue<Quote>;
    isUpdating: boolean;
    queryClient: QueryClient;
    setQuoteId: (quoteId: string) => void;
    onSubmit: SubmitHandler<QuoteFinalized>;
    currentStep: UsQuoteFormStepIds;
    priorityCode?: string;
    updateCurrentStep: (stepId: UsQuoteFormStepIds, quoteId?: string) => void;
    isApplyAllHidden?: boolean;
    nameFieldValues: StatsigNameFields;
    showBillingSubmitButton?: boolean;
    devIsEmailRegisterCheckEnabled?: boolean;
    isNoStepVariant?: boolean;
    showVideoTestimonials?: boolean;
};

const FORM_ID = PublicConfig.PTZ_US.FORM_ID;
const ACTIVE_OUTAGES = PublicConfig.PTZ_US.ACTIVE_OUTAGES;

const DEFAULT_COVERAGE_SETTINGS: CoverageSettings = {
    coverages: [{ type: "accident" }, { type: "illness" }],
    amounts: {
        reimbursementRate: PublicConfig.PTZ_US.DEFAULT_REIMBURSMENT_RATE,
        annualDeductible: PublicConfig.PTZ_US.DEFAULT_ANNUAL_DEDUCTIBLE,
        annualLimit: PublicConfig.PTZ_US.DEFAULT_ANNUAL_LIMIT
    }
};

const petsStepTitle = (
    <div className="flex flex-col">
        <Heading level="h1" className="text-lg font-bold">
            {Strings.ENTER_PET_INFO}
        </Heading>
        <div className="flex flex-col gap-2 md:flex-row md:items-center">
            <p className="text-sm">{Strings.MORE_PETS}</p>
            <div>
                <Badge variant="secondary" className="md:mb-1 md:mt-0">
                    10% multi-pet discount on all additional pets
                </Badge>
            </div>
        </div>
    </div>
);

export const getUsQuoteForm = ({
    underwriter,
    updateAppState,
    isUpdating,
    queryClient,
    setQuoteId,
    onSubmit,
    currentStep,
    priorityCode,
    updateCurrentStep,
    updateQuote,
    nameFieldValues,
    showBillingSubmitButton,
    devIsEmailRegisterCheckEnabled,
    isNoStepVariant,
    showVideoTestimonials
}: UsQuoteFormProps): FormConfig<UsQuoteFormStepIds, Quote> => {
    const quoteApi = new QuoteAPI(underwriter);
    const builderUtils = new BuilderUtils(underwriter);
    const { showFirstName, showLastName } = nameFieldValues;

    const emailUtils = new EmailUtils(underwriter, queryClient);

    const getPolicyStepFieldStyles = () => {
        if (!showFirstName && !showLastName) {
            return {
                zipCode: "order-1 lg:col-span-2",
                email: "order-2 lg:col-span-2",
                phone: "order-3 lg:col-span-2"
            };
        }
        if (showFirstName && !showLastName) {
            return {
                zipCode: "order-1 lg:col-span-3",
                email: "order-2 lg:col-span-3",
                firstName: "order-3 lg:col-span-3",
                phone: "order-4 lg:col-span-3"
            };
        }
        return {
            zipCode: "order-1 lg:col-span-3",
            email: "order-2 lg:col-span-3",
            firstName: "order-3 lg:col-span-2",
            lastName: "order-4 lg:col-span-2",
            phone: "order-5 lg:col-span-2"
        };
    };

    const getPolicyStepSchema = () => {
        if (!showFirstName && !showLastName) {
            return UsPolicyStepSchema.omit({ firstName: true, lastName: true });
        }
        if (showFirstName && !showLastName) {
            return UsPolicyStepSchema.omit({ lastName: true });
        }
        return UsPolicyStepSchema;
    };

    const getPolicyStepTitle = () => {
        if (isNoStepVariant) return petsStepTitle;
        return Strings.ENTER_PET_INFO;
    };

    const stepsConfig: FormStep<Quote, UsQuoteFormStepIds>[] = [
        {
            id: UsQuoteFormSteps.PETS.id,
            continueButtonTitle: Strings.PTZ_US.SELECT_COVERAGE_TEXT,
            stepSchema: getPolicyStepSchema(),
            title: getPolicyStepTitle(),
            getInitialValues: quote => PtzUsDataUtils.getPolicyStepInitialValues(quote),
            disclaimerContent: props => (
                <>
                    <CustomizationSlot
                        type="above-disclaimer"
                        data={props.value}
                        formId={FORM_ID}
                        formStepId={UsQuoteFormSteps.PETS.id}
                        formData={props.value?.extra?.formData}
                        updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                        priorityCode={priorityCode}
                    />
                    <FreePetDisclaimer />
                </>
            ),
            allowSubmit: () => !isUpdating,
            allowContinue: async values => {
                if (!values) return false;
                updateAppState({ asyncErrors: undefined, isQuoteUpdating: true });
                const isNewQuote = !values?.id;
                const currentQuote = queryClient.getQueryData(["quote", values.id]) as Quote | undefined;
                const hasQuoteChanged = !isEqual(currentQuote, values);

                // Determine if we need to run email checks
                const emailInQuote = currentQuote?.email;
                const shouldCheckEmail = !values?.id || (!!values.email && emailInQuote !== values.email);

                const cookieId = PublicConfig.PTZ_US.COOKIE_QUOTE_ID_KEY;
                const existingCookieQuoteId = Cookies.get(cookieId);

                if (shouldCheckEmail && !!values.email) {
                    const tasks: Promise<boolean>[] = [];

                    if (PublicConfig.ENVIRONMENT === "production" || devIsEmailRegisterCheckEnabled) {
                        tasks.push(
                            emailUtils
                                .verifyEmailRegistration(values.email)
                                .then(emailIsRegistered => {
                                    if (emailIsRegistered) {
                                        updateAppState({ asyncErrors: [{ id: "account-exists" }], isQuoteUpdating: false });
                                        return false;
                                    }
                                    return true;
                                })
                                .catch(error => {
                                    console.error("Error verifying email", error);
                                    return true;
                                })
                        );
                    }

                    tasks.push(
                        emailUtils.handleEmailDebounce({
                            email: values.email,
                            debounce: values?.extra?.debounce,
                            updateAppState
                        })
                    );

                    const results = await Promise.all(tasks);

                    if (results.some(result => result === false)) {
                        return false;
                    }
                }

                // If this is a brand new quote or if something has changed on the policy step form, we need to update the quote
                if (isNewQuote || hasQuoteChanged) {
                    try {
                        const policyData = QuoteDataUtils.quoteToPetQuote({ ...values, lastStepID: UsQuoteFormSteps.COVERAGE.id });

                        const updatedPetQuote = await quoteApi.updateQuote(policyData);

                        if (!updatedPetQuote?.quoteId) {
                            throw new Error("No quote ID in response");
                        }
                        const updatedQuote = QuoteDataUtils.petQuoteToQuote(updatedPetQuote, underwriter);
                        queryClient.setQueryData<Quote>(["quote", updatedPetQuote.quoteId], updatedQuote);
                        setQuoteId(updatedPetQuote.quoteId);

                        if (isNewQuote) {
                            const quoteProperties = TrackingUtils.buildTrackingPayload(updatedPetQuote);
                            AnalyticsUtils?.trackSegmentEvent(`Checkout Started`, quoteProperties);
                        }
                        updateAppState({ hasUnknownError: false, isQuoteUpdating: false });
                        if (existingCookieQuoteId !== updatedPetQuote.quoteId) {
                            Cookies.set(cookieId, updatedPetQuote.quoteId, { expires: 365 });
                        }
                        return updatedQuote;
                    } catch (error) {
                        if (isAxiosError(error)) {
                            const errorStatus = error?.response?.status ?? 0;
                            if (quoteApi.isSpotApiError(error.response?.data)) {
                                const asyncErrors = quoteApi.getErrorIds(error?.response?.data);
                                updateAppState({ asyncErrors, isQuoteUpdating: false });
                                return false;
                            }
                            // For unhandled errors, throw a generic "unknown" error to be caught by the global error handler (ex: appState.hasUnknownError)
                            if (errorStatus >= 400) {
                                updateAppState({ hasUnknownError: true, isQuoteUpdating: false });
                                return false;
                            }
                        }
                        return false;
                    }
                } else {
                    // Before allowing the user to continue to the coverage editor, we check to make sure all the policies have valid data and if not, we apply the defaults

                    const validationResults = values.policies?.map(policy => PolicySchema.safeParse(policy)) ?? [];
                    const invalidPolicies = validationResults.filter(result => !result.success);

                    if (invalidPolicies.length > 0) {
                        // Some policies failed validation, apply default coverage settings
                        const updatedPolicies = values.policies?.map((policy, index) => {
                            if (!validationResults[index]?.success) {
                                return {
                                    ...policy,
                                    coverageSettings: DEFAULT_COVERAGE_SETTINGS
                                };
                            }
                            return policy;
                        });

                        // Update the quote with the new policies
                        const updatedQuote = {
                            ...values,
                            policies: updatedPolicies
                        };
                        try {
                            const policyData = QuoteDataUtils.quoteToPetQuote({ ...updatedQuote, lastStepID: UsQuoteFormSteps.COVERAGE.id });
                            const updatedPetQuote = await quoteApi.updateQuote(policyData);

                            if (!updatedPetQuote?.quoteId) {
                                throw new Error("No quote ID in response");
                            }

                            const finalUpdatedQuote = QuoteDataUtils.petQuoteToQuote(updatedPetQuote, underwriter);
                            queryClient.setQueryData<Quote>(["quote", updatedPetQuote.quoteId], finalUpdatedQuote);
                            setQuoteId(updatedPetQuote.quoteId);

                            updateAppState({ hasUnknownError: false, isQuoteUpdating: false });
                            if (existingCookieQuoteId !== updatedPetQuote.quoteId) {
                                Cookies.set(cookieId, updatedPetQuote.quoteId, { expires: 365 });
                            }
                            return finalUpdatedQuote;
                        } catch (error) {
                            console.error("Error updating quote with default coverage settings:", error);
                            updateAppState({ hasUnknownError: true, isQuoteUpdating: false });
                            return false;
                        }
                    }

                    // If all policies in quote are valid and have pricing, we can update the lastStepID via the scratchpad
                    updateAppState({ hasUnknownError: false, isQuoteUpdating: false });
                    if (!values.lastStepID && !!values.id && !!updateQuote) {
                        await quoteApi
                            .setScratchPadValue(values.id, "extra", { quote: { lastStepID: UsQuoteFormSteps.COVERAGE.id, lastStepIDUpdatedAt: DateTime.utc().toISO() } })
                            .then(() => queryClient.invalidateQueries({ queryKey: ["quote"] }));
                        updateQuote("lastStepID", UsQuoteFormSteps.COVERAGE.id);
                        if (existingCookieQuoteId !== values.id) {
                            Cookies.set(cookieId, values.id, { expires: 365 });
                        }
                    }
                    return values;
                }
            },
            render: props => {
                return (
                    <>
                        <PolicyEditor {...props} isNoStepVariant={isNoStepVariant} />
                        <UserContactEditor
                            config={{
                                showFirstName: true,
                                showLastName: true,
                                showMobilePhone: true,
                                underwriter: "ptz-us",
                                errors: {
                                    "invalid-postal-code": Strings.ERRORS.ZIP_INVALID
                                },
                                zipField: {
                                    label: Strings.ZIP_CODE,
                                    mask: UIUtils.maskPostalCodeUS,
                                    showGiftCardWrapper: true,
                                    placeholder: " "
                                },
                                isNoStepVariant: isNoStepVariant
                            }}
                            styles={{
                                wrapper: "grid grid-cols-1 gap-6 lg:grid-cols-6",
                                fields: getPolicyStepFieldStyles()
                            }}
                            {...props}
                        />
                        <div className="mt-2 flex flex-col gap-4 text-xs text-content-secondary">
                            <ContactDisclaimer />
                            <GiftCardDisclaimer alignLeft />
                        </div>
                        <CustomizationSlot
                            type="above-cta"
                            data={props.value}
                            formId={FORM_ID}
                            formStepId={UsQuoteFormSteps.PETS.id}
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                            priorityCode={priorityCode}
                        />
                    </>
                );
            }
        },
        {
            id: UsQuoteFormSteps.COVERAGE.id,
            isMaxDeterministicStep: true,
            bottomWidget: props => {
                const { roundedPrice, discountsAmount } = CoverageUtils.getPriceInfoData({ value: props?.value, includeTransactionFee: false });
                return <PriceInfo totalPrice={roundedPrice} policiesCount={props?.value?.policies?.length ?? 0} discountsAmount={discountsAmount} variant="inline" />;
            },
            hideBackButton: isNoStepVariant,
            continueButtonTitle: Strings.PROCEED_TO_CHECKOUT,
            disclaimerContent: props => {
                return (
                    <React.Fragment>
                        <CustomizationSlot
                            type="above-disclaimer"
                            data={props.value}
                            formId={FORM_ID}
                            formStepId={UsQuoteFormSteps.COVERAGE.id}
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                            priorityCode={priorityCode}
                        />
                        <GiftCardDisclaimer />
                    </React.Fragment>
                );
            },
            render: props => {
                const coveragePresetData: PresetCoverageLevel[] = [
                    {
                        name: "Basic",
                        config: {
                            type: ["accident", "illness"],
                            deductible: PublicConfig.PTZ_US.DEFAULT_ANNUAL_DEDUCTIBLE,
                            reimbursementPercent: PublicConfig.PTZ_US.DEFAULT_REIMBURSMENT_RATE,
                            annualLimit: PublicConfig.PTZ_US.DEFAULT_ANNUAL_LIMIT
                        }
                    }
                ];

                const discountCode = props.value?.discountCode?.toLowerCase();

                const showTakeoverProvision = PublicConfig.TAKEOVER_PROVISION_PCODES.includes(discountCode) || (discountCode && discountCode.includes("ebtp"));

                return (
                    <>
                        <CoverageEditor
                            {...props}
                            editorConfig={{
                                formId: FORM_ID,
                                title: "Create your plan",
                                includeTransactionFee: false,
                                coveragePresetData,
                                termsInModal: ["annualDeductible", "annualLimit", "reimbursement"],
                                samplePolicyUrl: SAMPLE_POLICY_URL,
                                modalContent: modalContent,
                                preventiveConfig: {
                                    labels: {
                                        basic: Strings.PTZ_US.PREVENTIVE_BASIC,
                                        advanced: Strings.PTZ_US.PREVENTIVE_ADVANCED
                                    }
                                },
                                exclusions: <Exclusions />,
                                customizationSlot: (
                                    <CustomizationSlot
                                        type="above-cta"
                                        data={props.value}
                                        formId={FORM_ID}
                                        formStepId={UsQuoteFormSteps.COVERAGE.id}
                                        formData={props.value?.extra?.formData}
                                        updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                                        priorityCode={priorityCode}
                                    />
                                ),
                                petModalConfig: {
                                    resolverSchema: UsPetPoliciesSchema,
                                    topBreeds: TOP_BREEDS,
                                    showMicrochip: false,
                                    formStyles: {
                                        wrapper: "grid grid-cols-2 gap-y-6 gap-x-4",
                                        fields: {
                                            name: "order-1 col-span-2",
                                            age: "order-2 col-span-2",
                                            species: "order-3 col-span-1",
                                            gender: "order-4 col-span-1",
                                            breed: "order-5 col-span-2"
                                        }
                                    },
                                    ageField: undefined // US doesn't have specific age field configuration
                                },
                                getModalInitialValues: QuoteDataUtils.createNewPolicyUS,
                                showTakeoverProvision: showTakeoverProvision,
                                priorityCode: priorityCode
                            }}
                        />
                    </>
                );
            },
            hidePreviousSteps: true,
            allowSubmit: values => {
                if (!values) return false;
                if (isUpdating) return false;

                let isValid = true;

                values?.policies?.forEach(policy => {
                    if (!policy) {
                        isValid = false;
                        return;
                    }

                    // Using safeParse for validation
                    const validationResult = PolicySchema.safeParse(policy);

                    if (!validationResult.success) {
                        isValid = false;
                    }
                });

                // Return false if any policy was invalid, true otherwise
                return isValid;
            },
            allowContinue: async values => {
                if (!values) return Promise.resolve(false);
                updateAppState({ asyncErrors: undefined, isQuoteUpdating: true });
                const currentQuote = queryClient.getQueryData(["quote", values.id]) as Quote | undefined;
                const hasQuoteChanged = !isEqual(currentQuote, values);

                if (hasQuoteChanged) {
                    try {
                        const policyData = QuoteDataUtils.quoteToPetQuote({
                            ...values,
                            lastStepID: ACTIVE_OUTAGES.includes("ptz-us-billing-api") ? UsQuoteFormSteps["BILLING-OUTAGE"].id : UsQuoteFormSteps.BILLING.id
                        });

                        const updatedPetQuote = await quoteApi.updateQuote(policyData);

                        if (!updatedPetQuote?.quoteId) {
                            throw new Error("No quote ID in response");
                        }
                        const updatedQuote = QuoteDataUtils.petQuoteToQuote(updatedPetQuote, underwriter);
                        queryClient.setQueryData(["quote", updatedPetQuote.quoteId], updatedQuote);
                        updateAppState({ hasUnknownError: false, isQuoteUpdating: false });
                        return true;
                    } catch (error) {
                        if (isAxiosError(error)) {
                            const errorStatus = error?.response?.status ?? 0;

                            if (quoteApi.isSpotApiError(error.response?.data)) {
                                const asyncErrors = quoteApi.getErrorIds(error?.response?.data);
                                updateAppState({ asyncErrors, isQuoteUpdating: false });
                                return false;
                            }

                            // For unhandled errors, throw a generic "unknown" error to be caught by the global error handler (ex: appState.hasUnknownError)
                            if (errorStatus >= 400) {
                                updateAppState({ hasUnknownError: true, isQuoteUpdating: false });
                                return false;
                            }
                        }
                        return false;
                    }
                } else {
                    updateAppState({ hasUnknownError: false, isQuoteUpdating: false });
                    if (!!values.id && !!updateQuote) {
                        const newStepID = ACTIVE_OUTAGES.includes("ptz-us-billing-api") ? UsQuoteFormSteps["BILLING-OUTAGE"].id : UsQuoteFormSteps.BILLING.id;
                        await quoteApi
                            .setScratchPadValue(values.id, "extra", { quote: { lastStepID: newStepID, lastStepIDUpdatedAt: DateTime.utc().toISO() } })
                            .then(() => queryClient.invalidateQueries({ queryKey: ["quote"] }));
                        updateQuote("lastStepID", newStepID);
                    }
                    return true;
                }
            }
        },
        {
            id: "billing-outage",
            hideContinueButton: true,
            hidePreviousSteps: true,
            shouldSkip: !ACTIVE_OUTAGES.includes("ptz-us-billing-api"),
            render: props => <BillingOutage {...props} underwriter={underwriter} />
        },
        {
            id: UsQuoteFormSteps.BILLING.id,
            hideContinueButton: !showBillingSubmitButton,
            stepSchema: isNoStepVariant ? ExtendedBillingStepSchema : BillingStepSchema,
            getInitialValues: quote => QuoteDataUtils.getBillingStepInitialValues(quote, isNoStepVariant),
            continueButtonTitle: "Complete Checkout",
            bottomWidget: props => {
                const { roundedPrice, discountsAmount } = CoverageUtils.getPriceInfoData({ value: props?.value, includeTransactionFee: true });
                return <PriceInfo totalPrice={roundedPrice} policiesCount={props?.value?.policies?.length ?? 0} discountsAmount={discountsAmount} variant="inline" />;
            },
            allowSubmit: () => !isUpdating,
            allowContinue: async values => {
                if (!values) return false;
                if (!values.phone) return false;
                updateAppState({ asyncErrors: undefined, isQuoteUpdating: true, showLoaderDialog: true });
                try {
                    const extraWithThankYou = {
                        ...values.extra,
                        thankYouURL: `${window.location.origin}${PublicConfig.BASE_PATH}/forms/${FORM_ID}/thankyou?uw=${underwriter}&quoteId=${values.id}`
                    };
                    await onSubmit({ ...values, extra: extraWithThankYou } as QuoteFinalized);
                    updateAppState({ isQuoteUpdating: false });
                    return true;
                } catch (error) {
                    updateAppState({ showLoaderDialog: false, isQuoteUpdating: false });
                    if (error instanceof Error && !!error?.message && error?.message.toLowerCase().includes("billing zipcode")) {
                        const asyncErrors = [{ id: `invalid-postal-code`, at: "zipcode" }] as SpotErrorMessage[];
                        updateAppState({ asyncErrors });
                    }
                    console.error(error);
                    return false;
                }
            },
            render: props => {
                const { roundedPrice } = CoverageUtils.getPriceInfoData({ value: props.value, includeTransactionFee: true });
                return (
                    <React.Fragment>
                        <StripeProvider amount={roundedPrice}>
                            <BillingEditor {...props} isNoStepVariant={isNoStepVariant} />
                        </StripeProvider>
                        <CustomizationSlot
                            type="above-cta"
                            data={props.value}
                            formId={FORM_ID}
                            formStepId={UsQuoteFormSteps.BILLING.id}
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                            priorityCode={priorityCode}
                        />
                    </React.Fragment>
                );
            },
            disclaimerContent: props => {
                return (
                    <React.Fragment>
                        <CustomizationSlot
                            type="above-disclaimer"
                            data={props.value}
                            formId={FORM_ID}
                            formStepId={UsQuoteFormSteps.BILLING.id}
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: updateQuote })}
                            priorityCode={priorityCode}
                        />
                        <GiftCardDisclaimer />
                    </React.Fragment>
                );
            },
            hidePreviousSteps: true
        }
    ];

    const stepsForStepper: StepperConfig<UsQuoteFormStepIds>[] = isNoStepVariant
        ? []
        : stepsConfig.map(step => ({
              stepValue: step.id as UsQuoteFormStepIds,
              shouldSkip: step.shouldSkip
          }));

    return {
        id: FORM_ID,
        schema: PartialQuoteSchema,
        header: (value, otherValues, isUpdating) => {
            return (
                <SiteHeader
                    quote={value}
                    updateQuote={updateQuote}
                    formID={FORM_ID}
                    underwriter={underwriter}
                    phoneNumber={Strings.PTZ_US.PHONE_NUMBER}
                    logo={<SpotLogo />}
                    currentStep={currentStep}
                    steps={stepsForStepper}
                    updateCurrentStep={updateCurrentStep}
                />
            );
        },
        steps: stepsConfig,
        footer: (value, otherValues, isUpdating) => {
            const extendedDisclaimers = [
                {
                    pcode: PublicConfig.PTZ_US.FORBES_QUOTERS_PCODE,
                    disclaimer: (
                        <div className="leading-5">
                            † Comparison information is provided using publicly available information as of 8/28/24 and is only meant to summarize program features, not a specific
                            plan. Review each provider’s plan terms for more details. The descriptions of other providers’ plans were not provided by that company. If you have
                            questions about other plans, please contact an agent of that company. It is our intention to provide fair and accurate comparison information. Although
                            we attempt to keep information up to date, it may change from time to time. If you are aware of any inaccuracies or changes in the information provided,
                            let us know by visiting{" "}
                            <Link className="underline" href={`${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/complaints-and-feedback`}>
                                spotpet.com/complaints-and-feedback
                            </Link>{" "}
                            . Once your deductible is met, exam fees for accidents and illnesses related to covered conditions are covered. Annual physical exam fees are not
                            covered. With Embrace an additional monthly fee is required. Prescription food & supplements are covered if they are prescribed to treat an eligible
                            accident or illness. Prescription food & supplements are not covered if they are used for weight management or general health maintenance.
                        </div>
                    )
                }
            ];

            const disclaimerToShow = extendedDisclaimers.find(disclaimer => disclaimer.pcode === priorityCode);
            return (
                <React.Fragment>
                    <SiteFooter
                        underwriter={underwriter}
                        formID={FORM_ID}
                        currentStep={currentStep}
                        links={US_FOOTER_LINKS}
                        content={<FooterContent />}
                        extraContent={disclaimerToShow?.disclaimer}
                        copyright={Strings.PTZ_US.COPYRIGHT_TEXT}
                        hasOffset={false}
                        priorityCode={priorityCode}
                        showVideoTestimonials={showVideoTestimonials}
                    />
                </React.Fragment>
            );
        }
    };
};
