import React, { useEffect, useRef, useMemo, useState, useCallback, FunctionComponent } from "react";
import { z } from "zod";
import { ExpressCheckoutElement } from "@stripe/react-stripe-js";
import { StripeExpressCheckoutElementConfirmEvent, StripeExpressCheckoutElementReadyEvent, StripeExpressCheckoutElementClickEvent } from "@stripe/stripe-js";
import { AnimatePresence, motion } from "framer-motion";

// utils
import { US_STATES } from "@/shared/utils/constants";
import { UIUtils } from "@/shared/utils/UIUtils";
import { PublicConfig } from "@/shared/PublicConfig";
import { cn } from "@/shared/utils";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";
import Strings from "@/shared/utils/Strings.constants";
import { CoverageUtils } from "@/shared/utils/CoverageUtils";
import StripeUtils from "./StripeCheckout/StripeUtils";

// components
import Terms from "./Terms";
import phone from "phone";
import StripeCheckout from "./StripeCheckout";
import PolicySummary from "@/shared/components/PolicySummary";
import { FormField } from "@/shared/components/FormField";
import { Select } from "@/shared/components/ui/Select";
import { Heading } from "@/shared/components/ui/Heading";
import { InputWithLabel } from "@/shared/components/ui/InputWithLabel";
import { RadioButtonGroup } from "@/shared/components/ui/RadioButtonGroup";
import { Button, Checkbox, Label } from "@/shared/components/ui";
import { ContactConsentModal } from "./ContactConsentModal";
import { FormHint } from "@/shared/components/FormHint";
import StripePaymentElement from "./StripeCheckout/StripePaymentElement";

// hooks
import { useBreeds } from "@/shared/hooks/useBreeds";
import { useFormContext, Controller, FieldPath } from "react-hook-form";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useFormParentContext } from "@/shared/contexts/FormParent";
import { useStateFromZipcode } from "@/shared/hooks/useRatingAddress";
import { useModal } from "@/shared/hooks/useModal";

// media
import DollarIcon from "../../../../src/media/icons/dollars-icon.svg";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// types
import { ErrorIdType } from "@/shared/types/SpotAPI";
import { NonNullableKeys, ObjectPaths } from "@/shared/utils/TypeUtils";
import { FormStepProps, OtherProps } from "@/shared/types/Form";
import { Quote, ExtendedBillingStepSchema, StripeExpressPaymentSchema } from "@/shared/types/Quote.interface";

// Types for Form and Frequency
export type AllBillingStepProps = z.infer<typeof ExtendedBillingStepSchema>;
type StepKeys = Extract<keyof Quote, keyof AllBillingStepProps>;
type OtherKeys = Exclude<keyof AllBillingStepProps, keyof Quote>;
type FrequencyType = "monthly" | "yearly";
type AllBillingStepPaths = ObjectPaths<NonNullableKeys<AllBillingStepProps>>;

const MAX_RETRIES = 3;
const RETRY_DELAY = 500;

type OnConfirmResult = {
    success: boolean;
    retryCount: number;
};

export function BillingEditor(props: FormStepProps<Quote, StepKeys, OtherProps | undefined> & { isNoStepVariant?: boolean }) {
    const { appState, updateAppState } = useAppLayerContext();
    const { asyncErrors, showCreditCardFields, priorityCode, formContinueButtonRef, updateQuote, isQuoteUpdating, breakpoint, billingShowTopExpressPayment } = appState;
    const quote = props?.value as Quote;
    const isStripeCheckoutEnabled = PublicConfig.PTZ_US.THIRD_PARTY_PAYMENT_PROCESSOR.toUpperCase() === "STRIPE";
    const isNoStepVariant = !!props.isNoStepVariant;

    useEffect(() => {
        if (billingShowTopExpressPayment) {
            updateAppState({ showBillingSubmitButton: true, showCreditCardFields: true });
        }
    }, [billingShowTopExpressPayment, updateAppState]);

    const {
        control,
        setValue,
        setError,
        setFocus,
        clearErrors,
        watch,
        getValues,
        formState: { errors, submitCount }
    } = useFormContext<AllBillingStepProps>();

    const { getValues: getFormParentValues } = useFormParentContext();

    const billingState = watch("billingInfo.address.state");
    const billingZipcode = watch("billingInfo.address.zipCode");

    // Billing Frequency
    const calculatedFrequency = appState?.billingFrequency;
    const currentFrequency = quote?.billingInfo?.frequency ?? "monthly";
    const frequencyToDisplay = calculatedFrequency ?? currentFrequency;
    const [selectedBillingFrequency, setSelectedBillingFrequency] = useState<FrequencyType>(frequencyToDisplay);

    const isMediumBreakpointOrLess = breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md";

    const { breeds } = useBreeds(PublicConfig.PTZ_US.UNDERWRITER);
    const { stateFromZip } = useStateFromZipcode(billingZipcode);

    const firstName = props.value?.firstName;
    const lastName = props.value?.lastName;
    const email = props.value?.email;

    const isPetParent = firstName?.toLowerCase() === "pet" && lastName?.toLowerCase() === "parent";

    const needsFirstLastName = !firstName || !lastName;
    const modal = useModal();
    const [hasExpressPaymentMethod, setHasExpressPaymentMethod] = useState(false);
    const currentPhone = watch("phone");

    //Policy terms modal
    const handleOpenModal = useCallback(
        (ModalComponent: FunctionComponent<any>, modalProps?: any) => {
            modal.openModal(ModalComponent, modalProps || {});
        },
        [modal]
    );

    const showStripeCheckout = billingShowTopExpressPayment && isStripeCheckoutEnabled;

    const handleFrequencyChange = async (newValue: FrequencyType) => {
        if (isQuoteUpdating) return;

        // Update the form state for the frequency value
        setValue("billingInfo.frequency", newValue, { shouldTouch: true, shouldDirty: true });
        setSelectedBillingFrequency(newValue);

        try {
            // Update the quote asynchronously
            const updatedQuote = await updateQuote?.mutateAsync({
                ...getFormParentValues(),
                billingInfo: { frequency: newValue }
            });

            // Ensure the frequency is correctly set from the updated quote
            const frequencyInResponse = (updatedQuote?.billingInfo?.frequency ?? "monthly") as FrequencyType;

            setValue("billingInfo.frequency", frequencyInResponse, { shouldTouch: true, shouldDirty: true });
            setSelectedBillingFrequency(frequencyInResponse);
            // Update the app state with the updated frequency
            updateAppState({
                billingFrequency: undefined,
                isAnnualBilling: frequencyInResponse === "yearly"
            });
        } catch (error) {
            console.error("Error updating quote frequency:", error);
            setSelectedBillingFrequency(currentFrequency);
            updateAppState({
                billingFrequency: undefined,
                isAnnualBilling: quote?.billingInfo?.frequency === "yearly"
            });
        }
    };

    const handleExtraChange = async (updateValue: any) => {
        const currentValues = getFormParentValues();
        if (isQuoteUpdating) return;
        try {
            const currentExtra = currentValues?.extra;
            const mergedExtra = { ...currentExtra, ...updateValue };
            updateQuote?.mutate({ ...currentValues, extra: mergedExtra });
        } catch (error) {
            console.log(error);
        }
    };

    const allBillingErrorsMap: Partial<Record<ErrorIdType, { path?: AllBillingStepPaths; message: string; stripe?: boolean; header?: string }>> = useMemo(
        () => ({
            "invalid-billing-address": { path: "billingInfo.address.street1", message: "Invalid billing address" },
            "invalid-billing-name": { path: "billingInfo.nameOnCard", message: "Invalid billing name" },
            "invalid-credit-card-name": { path: "billingInfo.nameOnCard", message: "Invalid billing name" },
            "invalid-phone-number": { path: "phone", message: "Invalid phone number" },
            "invalid-postal-code": { path: "billingInfo.address.zipCode", message: Strings.ERRORS.ZIP },
            "invalid-credit-card-cvv": { stripe: true, message: "", header: Strings.ERRORS.CARD_CVV },
            "invalid-credit-card-declined": { stripe: true, message: "" },
            "invalid-credit-card-number": { stripe: true, message: "" }
        }),
        []
    );

    useEffect(() => {
        if (!!asyncErrors && asyncErrors?.length > 0 && submitCount > 0) {
            clearErrors();

            let handledAllErrors = true;
            let asyncStripeError: any | undefined = undefined;

            asyncErrors.forEach(error => {
                const errorMapping = allBillingErrorsMap[error.id];
                if (!!errorMapping && !!errorMapping.path) {
                    setError(errorMapping.path, { message: errorMapping.message });
                    setFocus(errorMapping.path);
                } else if (!!errorMapping && errorMapping?.stripe) {
                    asyncStripeError = errorMapping;
                } else {
                    handledAllErrors = false;
                }
            });

            // Handle Stripe specific PaymentElement errors
            if (!!asyncStripeError?.stripe) {
                updateAppState({ stripePaymentElementError: asyncStripeError });
            }

            // If we encounter an error that can't be handled, we set a flag to show a generic error message
            if (!handledAllErrors) {
                updateAppState({ hasUnknownError: true });
            }
        }
    }, [allBillingErrorsMap, asyncErrors, clearErrors, setError, setFocus, submitCount, updateAppState]);

    // Changes State value based on Zip Code value
    useEffect(() => {
        if (!!stateFromZip?.abbreviation && billingState !== stateFromZip.abbreviation) {
            setValue(`billingInfo.address.state`, stateFromZip.abbreviation, { shouldTouch: true, shouldDirty: true });
        }
    }, [setValue, stateFromZip?.abbreviation, billingState]);

    // Clear any payment element errors when the user switches between payment methods
    useEffect(() => {
        if (!showCreditCardFields) {
            updateAppState({ stripePaymentElementError: undefined });
        }
    }, [showCreditCardFields, updateAppState]);

    const onConfirm = async (event: StripeExpressCheckoutElementConfirmEvent, retryAttempt: number = 0): Promise<OnConfirmResult> => {
        AnalyticsUtils.sendDataDogAction("Stripe Express Checkout Confirm Clicked");
        const { billingDetails } = event;
        let zipCodeValue = billingDetails?.address.postal_code ?? "";
        if (zipCodeValue.length > 5) {
            zipCodeValue = zipCodeValue.slice(0, 5);
        }

        setValue("billingInfo.address.city", billingDetails?.address.city ?? "");
        setValue("billingInfo.address.state", billingDetails?.address.state ?? "");
        setValue("billingInfo.address.country", billingDetails?.address.country ?? "");
        setValue("billingInfo.address.zipCode", zipCodeValue);
        setValue("billingInfo.address.street1", billingDetails?.address.line1 ?? "");
        setValue("billingInfo.address.street2", billingDetails?.address.line2 ?? "");

        if (!currentPhone) {
            const formattedPhone = phone(billingDetails?.phone ?? "");
            const transformedPhone = UIUtils.transformPhoneNumber(formattedPhone.phoneNumber ?? "");
            setValue("phone", transformedPhone);
        }

        if (formContinueButtonRef?.current?.click) {
            formContinueButtonRef.current.click();
            return { success: true, retryCount: retryAttempt };
        } else if (retryAttempt < MAX_RETRIES) {
            AnalyticsUtils.sendDataDogLog("warn", `PAYMENT - Retry attempt ${retryAttempt + 1} for clicking continue button`);
            console.log(`Retry attempt ${retryAttempt + 1} for clicking continue button`);
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
            return onConfirm(event, retryAttempt + 1);
        } else {
            console.error("Failed to click the continue button after maximum retries");
            return { success: false, retryCount: retryAttempt };
        }
    };

    const firstTermsCheckboxRef = useRef<HTMLElement | null>(null);
    const setFirstTermsCheckboxRef = useCallback((element: HTMLElement | null) => {
        firstTermsCheckboxRef.current = element;
    }, []);

    //express checkout
    const onExpressCheckoutElementClick = (event: StripeExpressCheckoutElementClickEvent) => {
        if (isQuoteUpdating) return;

        const { expressPaymentType } = event;

        const currentValues = getValues();
        const result = StripeExpressPaymentSchema.safeParse(currentValues);

        if (!result.success) {
            let isFirstError = true;
            result.error.errors.forEach(err => {
                const fieldName = err.path.join(".") as FieldPath<typeof currentValues>;
                setError(fieldName, {
                    type: "manual",
                    message: err.message
                });
                if (isFirstError) {
                    // Focus on the first terms checkbox if it's not checked
                    if (fieldName === "extra.termsConsent" && firstTermsCheckboxRef.current) {
                        setTimeout(() => {
                            if (firstTermsCheckboxRef.current instanceof HTMLElement) {
                                firstTermsCheckboxRef.current.focus();
                            }
                        }, 600);
                    } else {
                        setTimeout(() => setFocus(fieldName as FieldPath<AllBillingStepProps>), 600);
                    }
                    isFirstError = false;
                }
            });

            return;
        }

        if (expressPaymentType) {
            updateAppState({ stripeExpressPaymentType: expressPaymentType, showBillingSubmitButton: true });
        }
        AnalyticsUtils.sendDataDogAction("Stripe Express Checkout Clicked", { expressPaymentType });
        event.resolve({ phoneNumberRequired: !currentPhone });
    };

    const onExpressCheckoutLoad = (event: StripeExpressCheckoutElementReadyEvent) => {
        const { availablePaymentMethods } = event;

        if (availablePaymentMethods) {
            const hasPaymentMethod = Object.values(availablePaymentMethods).some(value => !!value);
            setHasExpressPaymentMethod(hasPaymentMethod);
            updateAppState({ stripeAvailablePaymentMethods: availablePaymentMethods });
        }
    };

    const onExpressCheckoutCancel = () => {
        updateAppState({ stripeExpressPaymentType: undefined });
        AnalyticsUtils.sendDataDogAction("Stripe Express Checkout Cancelled");
    };

    const conditionalExpressClasses = {
        "my-1": hasExpressPaymentMethod,
        "opacity-50": isQuoteUpdating
    };

    //credit card and address
    const showCreditCardSection = showStripeCheckout && (showCreditCardFields || billingShowTopExpressPayment);

    /////Statsig test /////////////////////////////
    const topBillingFrequency = () => {
        if (!isMediumBreakpointOrLess) return null;
        if (!billingShowTopExpressPayment) return null;

        const policies = quote?.policies ?? [];
        const transactionFee = quote?.transactionFee?.value ?? 0;
        const totalPrice = CoverageUtils.calculatePolicyTotal(policies);

        // Calculate monthly and annual price based on the selected frequency
        const formattedMonthlyPrice =
            frequencyToDisplay === "monthly"
                ? UIUtils.formatNumber(totalPrice + transactionFee, 2) // Total price for monthly
                : UIUtils.formatNumber(totalPrice / 12 + 2, 2); // Divided by 12 for yearly frequency

        const formattedAnnualPrice =
            frequencyToDisplay === "yearly"
                ? UIUtils.formatNumber(totalPrice, 2) // Total price for yearly
                : UIUtils.formatNumber(totalPrice * 12, 2); // Multiplied for yearly when monthly is selected

        const consentError = errors?.extra?.termsConsent?.message;
        const iaicVerbiageV3 = PublicConfig.PTZ_US.GIFTCARD_VERBIAGE_IAIC_V3;
        const quoteState = quote?.ratingAddress?.state;
        const isApplicantState = iaicVerbiageV3.includes(quoteState as string);
        const consentErrorClasses = {
            "border border-stroke-danger rounded-md p-4 shadow-md": consentError
        };

        const onTermsChange = (value: boolean) => {
            if (errors.extra?.termsConsent) {
                clearErrors("extra.termsConsent");
            }
            setValue("extra.termsConsent", value, { shouldTouch: true, shouldDirty: true });
        };

        return (
            <>
                {/* Billing frequency section with radio button group */}
                <div data-testid="billing-frequency-section" className="rounded-xl bg-background-success px-3 pb-1 pt-2">
                    <RadioButtonGroup
                        key={selectedBillingFrequency}
                        onValueChange={async value => await handleFrequencyChange(value as "monthly" | "yearly")}
                        initialValue={selectedBillingFrequency}
                        aria-labelledby="billing-frequency"
                        className="bg-background-weakest"
                        disabled={isQuoteUpdating}
                        options={[
                            {
                                label: <div className="text-content-secondary">${formattedMonthlyPrice} Monthly</div>,
                                value: "monthly"
                            },
                            {
                                label: <div className="text-content-secondary">${formattedAnnualPrice} Annually</div>,
                                value: "yearly"
                            }
                        ]}
                        data-testid="billing-frequency-group"
                    />
                    {/* Displaying savings message depending on the selected frequency */}
                    <div className="flex flex-row items-center justify-center gap-1 pt-2">
                        {frequencyToDisplay === "yearly" ? <FontAwesomeIcon icon={faCircleCheck} className="size-5 text-content-primary" /> : <DollarIcon />}
                        <span className="text-xs font-semibold text-content-primary">
                            {frequencyToDisplay === "yearly" ? "You're saving $24/yr!" : "Save $24/yr with annual billing."}
                        </span>
                    </div>
                </div>

                {/* Conditional rendering for Stripe checkout */}
                {showStripeCheckout && (
                    <div id="express-checkout-element" className={cn(conditionalExpressClasses)}>
                        <ExpressCheckoutElement
                            options={StripeUtils.expressPaymentOptions}
                            onReady={onExpressCheckoutLoad}
                            onClick={onExpressCheckoutElementClick}
                            onConfirm={onConfirm}
                            onCancel={onExpressCheckoutCancel}
                        />
                    </div>
                )}

                {/* Terms and conditions section */}
                <div className={cn("mx-auto flex max-w-xl flex-col")}>
                    <Controller
                        name="extra.termsConsent"
                        control={control}
                        render={({ field: { ref, value } }) => (
                            <div className={cn("flex flex-col transition-all", consentErrorClasses)}>
                                <FormField className="flex-row gap-3">
                                    <Checkbox
                                        id="terms-consent"
                                        aria-labelledby="terms-consent-label"
                                        checked={value}
                                        ref={(e: HTMLElement | null) => {
                                            if (typeof ref === "function") ref(e);
                                            setFirstTermsCheckboxRef(e);
                                        }}
                                        error={consentError}
                                        disabled={isQuoteUpdating}
                                        onCheckedChange={(value: boolean) => onTermsChange(value)}
                                    />
                                    <Label id="terms-consent-label" htmlFor="terms-consent" className="flex flex-col bg-background-transparent text-xs text-content-secondary">
                                        <p className="text-sm text-content-primary">
                                            I have read, accept the{" "}
                                            <Button
                                                onClick={() => handleOpenModal(ContactConsentModal)}
                                                className="text-sm text-content-primary underline hover:bg-background-transparent"
                                                variant="ghost"
                                                size="auto"
                                            >
                                                policy terms and fraud notice
                                            </Button>
                                        </p>
                                        {isApplicantState && (
                                            <>
                                                <p>By clicking the box, you are submitting your application.</p>
                                            </>
                                        )}
                                    </Label>
                                </FormField>
                                {errors?.extra?.termsConsent && (
                                    <FormHint variant="error" className="ml-6">
                                        {errors?.extra.termsConsent?.message}
                                    </FormHint>
                                )}
                            </div>
                        )}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {/* Statsig test */}
            {isMediumBreakpointOrLess && topBillingFrequency()}

            <div className="flex flex-col gap-7">
                <PolicySummary quote={quote} breeds={breeds} wrapperClass="md:hidden" />
                <div className="grid md:grid-cols-2 md:gap-8">
                    <div className="flex flex-col gap-7">
                        {isNoStepVariant && (
                            <div className="mx-auto flex w-full flex-col gap-3">
                                <Heading level="h2" className="text-lg font-bold">
                                    Contact Info
                                </Heading>
                                <div className="flex flex-col gap-6">
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field: { ref, ...rest }, fieldState }) => {
                                            return (
                                                <FormField className="min-w-[250px] flex-1" error={fieldState?.error?.message} errorId="error-email">
                                                    <InputWithLabel
                                                        label={Strings.EMAIL_ADDRESS}
                                                        variant="floating"
                                                        inputRef={ref}
                                                        error={fieldState?.error?.message}
                                                        inputProps={{
                                                            ...rest,
                                                            placeholder: "my@email.com",
                                                            type: "email",
                                                            autoComplete: "email",
                                                            "aria-describedby": errors?.email ? "error-email" : undefined
                                                        }}
                                                    />
                                                </FormField>
                                            );
                                        }}
                                    />
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        render={({ field: { ref, onChange, ...rest } }) => (
                                            <FormField className="min-w-[250px] flex-1" error={errors?.firstName?.message} errorId="error-given-name">
                                                <InputWithLabel
                                                    variant="floating"
                                                    label="First Name"
                                                    inputRef={ref}
                                                    error={errors?.billingInfo?.firstName?.message}
                                                    inputProps={{
                                                        ...rest,
                                                        autoComplete: "given-name",
                                                        onChange: e => {
                                                            if (errors?.billingInfo?.firstName) {
                                                                clearErrors("billingInfo.firstName");
                                                            }
                                                            onChange(e);
                                                            const lastName = getValues(`billingInfo.lastName`);
                                                            const newNameOnCard = `${e.target.value} ${lastName ?? ""}`;
                                                            setValue("billingInfo.firstName", e.target.value.trim().replace(/\s+/g, " "), { shouldTouch: true, shouldDirty: true });
                                                            setValue("billingInfo.nameOnCard", newNameOnCard.trim().replace(/\s+/g, " "), { shouldTouch: true, shouldDirty: true });
                                                            if (errors?.billingInfo?.nameOnCard) {
                                                                clearErrors("billingInfo.nameOnCard");
                                                            }
                                                        },
                                                        maxLength: 255
                                                    }}
                                                />
                                            </FormField>
                                        )}
                                    />
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        render={({ field: { ref, onChange, ...rest } }) => (
                                            <FormField className="min-w-[250px] flex-1" error={errors?.lastName?.message} errorId="error-family-name">
                                                <InputWithLabel
                                                    variant="floating"
                                                    label="Last Name"
                                                    inputRef={ref}
                                                    error={errors?.lastName?.message}
                                                    inputProps={{
                                                        ...rest,
                                                        autoComplete: "family-name",
                                                        onChange: e => {
                                                            if (errors?.billingInfo?.lastName) {
                                                                clearErrors("billingInfo.lastName");
                                                            }
                                                            onChange(e);
                                                            const firstName = getValues(`billingInfo.firstName`);
                                                            const newNameOnCard = `${firstName ?? ""} ${e.target.value}`;
                                                            setValue("billingInfo.lastName", e.target.value.trim().replace(/\s+/g, " "), { shouldTouch: true, shouldDirty: true });
                                                            setValue("billingInfo.nameOnCard", newNameOnCard.trim().replace(/\s+/g, " "), { shouldTouch: true, shouldDirty: true });
                                                            if (errors?.billingInfo?.nameOnCard) {
                                                                clearErrors("billingInfo.nameOnCard");
                                                            }
                                                        },
                                                        maxLength: 255
                                                    }}
                                                />
                                            </FormField>
                                        )}
                                    />
                                    <Controller
                                        name="phone"
                                        control={control}
                                        render={({ field: { ref, value, onChange, ...rest } }) => (
                                            <FormField error={errors?.phone?.message} errorId="error-phone">
                                                <InputWithLabel
                                                    variant="floating"
                                                    label={Strings.MOBILE_PHONE}
                                                    inputRef={ref}
                                                    error={errors?.phone?.message}
                                                    inputProps={{
                                                        ...rest,
                                                        autoComplete: "tel-national",
                                                        placeholder: "(555) 555-5555",
                                                        type: "tel",
                                                        value: UIUtils.maskPhone(value),
                                                        onChange: e => onChange(UIUtils.maskPhone(e.target.value)),
                                                        "aria-describedby": errors?.phone ? "error-phone" : undefined
                                                    }}
                                                />
                                            </FormField>
                                        )}
                                    />
                                </div>
                            </div>
                        )}
                        {(isPetParent || needsFirstLastName) && !isNoStepVariant && (
                            <div className="mx-auto flex w-full flex-col gap-3">
                                <Heading level="h2" className="text-lg font-bold">
                                    Pet Parent Info
                                </Heading>
                                <div className="flex flex-col gap-6">
                                    <Controller
                                        name="billingInfo.firstName"
                                        control={control}
                                        render={({ field: { ref, onChange, ...rest } }) => (
                                            <FormField className="min-w-[250px] flex-1" error={errors?.billingInfo?.firstName?.message} errorId="error-given-name">
                                                <InputWithLabel
                                                    variant="floating"
                                                    label="Your First Name"
                                                    inputRef={ref}
                                                    error={errors?.billingInfo?.firstName?.message}
                                                    inputProps={{
                                                        ...rest,
                                                        autoComplete: "given-name",
                                                        onChange: e => {
                                                            if (errors?.billingInfo?.firstName) {
                                                                clearErrors("billingInfo.firstName");
                                                            }
                                                            onChange(e);
                                                            const lastName = getValues(`billingInfo.lastName`);
                                                            const newNameOnCard = `${e.target.value} ${lastName ?? ""}`;
                                                            setValue("firstName", e.target.value.trim().replace(/\s+/g, " "), { shouldTouch: true, shouldDirty: true });
                                                            setValue("billingInfo.nameOnCard", newNameOnCard.trim().replace(/\s+/g, " "), { shouldTouch: true, shouldDirty: true });
                                                            if (errors?.billingInfo?.nameOnCard) {
                                                                clearErrors("billingInfo.nameOnCard");
                                                            }
                                                        },
                                                        maxLength: 255
                                                    }}
                                                />
                                            </FormField>
                                        )}
                                    />
                                    <Controller
                                        name="billingInfo.lastName"
                                        control={control}
                                        render={({ field: { ref, onChange, ...rest } }) => (
                                            <FormField className="min-w-[250px] flex-1" error={errors?.billingInfo?.lastName?.message} errorId="error-family-name">
                                                <InputWithLabel
                                                    variant="floating"
                                                    label="Your Last Name"
                                                    inputRef={ref}
                                                    error={errors?.billingInfo?.lastName?.message}
                                                    inputProps={{
                                                        ...rest,
                                                        autoComplete: "family-name",
                                                        onChange: e => {
                                                            if (errors?.billingInfo?.lastName) {
                                                                clearErrors("billingInfo.lastName");
                                                            }
                                                            onChange(e);
                                                            const firstName = getValues(`billingInfo.firstName`);
                                                            const newNameOnCard = `${firstName ?? ""} ${e.target.value}`;
                                                            setValue("lastName", e.target.value.trim().replace(/\s+/g, " "), { shouldTouch: true, shouldDirty: true });
                                                            setValue("billingInfo.nameOnCard", newNameOnCard.trim().replace(/\s+/g, " "), { shouldTouch: true, shouldDirty: true });
                                                            if (errors?.billingInfo?.nameOnCard) {
                                                                clearErrors("billingInfo.nameOnCard");
                                                            }
                                                        },
                                                        maxLength: 255
                                                    }}
                                                />
                                            </FormField>
                                        )}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="flex flex-col gap-3">
                            <Heading level="h2" className="text-lg font-bold">
                                {topBillingFrequency() && "Credit Card"} Payment Info
                            </Heading>
                            <div className="flex grow flex-col">
                                <div data-testid="billing-frequency-section" className="rounded-xl bg-background-success px-3 pb-1 pt-2">
                                    <RadioButtonGroup
                                        key={selectedBillingFrequency}
                                        onValueChange={async value => await handleFrequencyChange(value as "monthly" | "yearly")}
                                        initialValue={selectedBillingFrequency}
                                        aria-labelledby="billing-frequency"
                                        className="bg-background-weakest"
                                        disabled={isQuoteUpdating}
                                        options={[
                                            { label: "Billed Monthly", value: "monthly" },
                                            { label: "Billed Annually", value: "yearly" }
                                        ]}
                                    />
                                    <div className="flex flex-row items-center justify-center gap-1 pt-2">
                                        {frequencyToDisplay === "yearly" ? <FontAwesomeIcon icon={faCircleCheck} className="size-5 text-content-primary" /> : <DollarIcon />}
                                        <span className="text-xs font-semibold text-content-primary">
                                            {frequencyToDisplay === "yearly" ? "You're saving $24/yr!" : "Save $24/yr with annual billing."}
                                        </span>
                                    </div>
                                </div>

                                {isMediumBreakpointOrLess ? (
                                    <>
                                        {!showStripeCheckout && isStripeCheckoutEnabled && <StripeCheckout />}
                                        {showCreditCardSection && <StripePaymentElement />}
                                    </>
                                ) : (
                                    <>
                                        {/* Render StripeCheckout for lg and larger */}
                                        {isStripeCheckoutEnabled && <StripeCheckout />}
                                    </>
                                )}
                            </div>
                        </div>
                        <AnimatePresence mode="sync">
                            {showCreditCardFields && (
                                <motion.div key="outer" initial={{ height: 0 }} animate={{ height: `auto` }} exit={{ height: 0 }}>
                                    <motion.div key="inner" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.25 } }} exit={{ opacity: 0 }}>
                                        <div className="flex flex-col">
                                            <Heading level="h2" className="mb-3 text-lg font-bold">
                                                {topBillingFrequency() && "Credit Card"} Billing Address
                                            </Heading>
                                            <Controller
                                                name="billingInfo.nameOnCard"
                                                control={control}
                                                render={({ field: { ref, ...rest } }) => (
                                                    <FormField
                                                        className="mb-5 min-w-[250px]"
                                                        error={errors?.billingInfo?.nameOnCard?.message}
                                                        errorId="error-creditCard-nameOnCard"
                                                    >
                                                        <InputWithLabel
                                                            variant="floating"
                                                            label={Strings.PTZ_US.BILLING_NAME}
                                                            inputRef={ref}
                                                            error={errors?.billingInfo?.nameOnCard?.message}
                                                            inputProps={{
                                                                ...rest,
                                                                autoComplete: "cc-name",
                                                                "aria-describedby": errors?.billingInfo?.nameOnCard ? "error-creditCard-nameOnCard" : undefined,
                                                                maxLength: 101
                                                            }}
                                                        />
                                                    </FormField>
                                                )}
                                            />
                                            <div className="flex flex-col gap-5">
                                                <Controller
                                                    name="billingInfo.address.street1"
                                                    control={control}
                                                    render={({ field: { ref, onChange, ...rest } }) => (
                                                        <FormField
                                                            className="min-w-[250px] flex-1"
                                                            error={errors?.billingInfo?.address?.street1?.message}
                                                            errorId="error-address-street1"
                                                        >
                                                            <InputWithLabel
                                                                variant="floating"
                                                                label={Strings.PTZ_US.ADDRESS_LINE_1}
                                                                inputRef={ref}
                                                                error={errors?.billingInfo?.address?.street1?.message}
                                                                inputProps={{
                                                                    ...rest,
                                                                    onChange: event => {
                                                                        onChange(event);
                                                                    },
                                                                    autoComplete: "address-line1",
                                                                    "aria-describedby": errors?.billingInfo?.address?.street1 ? "error-address-street1" : undefined,
                                                                    maxLength: 255
                                                                }}
                                                            />
                                                        </FormField>
                                                    )}
                                                />

                                                <Controller
                                                    name="billingInfo.address.street2"
                                                    control={control}
                                                    render={({ field: { ref, onChange, ...rest } }) => (
                                                        <FormField
                                                            className="min-w-[250px] flex-1"
                                                            error={errors?.billingInfo?.address?.street2?.message}
                                                            errorId="error-address-street2"
                                                        >
                                                            <InputWithLabel
                                                                variant="floating"
                                                                label={Strings.PTZ_US.ADDRESS_LINE_2}
                                                                inputRef={ref}
                                                                error={errors?.billingInfo?.address?.street2?.message}
                                                                inputProps={{
                                                                    ...rest,
                                                                    onChange: event => {
                                                                        onChange(event);
                                                                    },
                                                                    autoComplete: "address-line2",
                                                                    "aria-describedby": errors?.billingInfo?.address?.street2 ? "error-address-street2" : undefined,
                                                                    maxLength: 255
                                                                }}
                                                            />
                                                        </FormField>
                                                    )}
                                                />
                                                <Controller
                                                    name="billingInfo.address.city"
                                                    control={control}
                                                    render={({ field: { ref, onChange, ...rest } }) => (
                                                        <FormField
                                                            className="min-w-[250px] flex-1"
                                                            error={errors?.billingInfo?.address?.city?.message}
                                                            errorId="error-address-city"
                                                        >
                                                            <InputWithLabel
                                                                variant="floating"
                                                                label="City"
                                                                inputRef={ref}
                                                                error={errors?.billingInfo?.address?.city?.message}
                                                                inputProps={{
                                                                    ...rest,
                                                                    onChange: event => {
                                                                        onChange(event);
                                                                    },
                                                                    autoComplete: "address-level2",
                                                                    "aria-describedby": errors?.billingInfo?.address?.city ? "error-address-city" : undefined,
                                                                    maxLength: 100
                                                                }}
                                                            />
                                                        </FormField>
                                                    )}
                                                />
                                                <div className="flex gap-5 lg:col-span-2 xl:col-span-1">
                                                    <FormField className="flex-1" error={errors?.billingInfo?.address?.state?.message} errorId="error-address-state">
                                                        <Label id="billing-address-state-label" variant="floating" size="xs" htmlFor="billing-address-state">
                                                            State
                                                        </Label>
                                                        <Controller
                                                            name="billingInfo.address.state"
                                                            control={control}
                                                            render={({ field: { onChange, value, ref } }) => (
                                                                <Select
                                                                    value={value || ""}
                                                                    options={US_STATES}
                                                                    onValueChange={val => {
                                                                        onChange(val);
                                                                        setValue("billingInfo.address.zipCode", "", { shouldDirty: true });
                                                                    }}
                                                                    placeholder="Select a state"
                                                                    ref={ref}
                                                                    error={errors?.billingInfo?.address?.state?.message}
                                                                    triggerProps={{
                                                                        id: "billing-address-state",
                                                                        "aria-labelledby": "billing-address-state-label",
                                                                        "aria-describedby": errors?.billingInfo?.address?.state ? "error-address-state" : undefined
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </FormField>

                                                    <Controller
                                                        name="billingInfo.address.zipCode"
                                                        control={control}
                                                        render={({ field: { ref, value, onChange, ...rest } }) => (
                                                            <FormField className="flex-1" error={errors?.billingInfo?.address?.zipCode?.message} errorId="error-address-zipCode">
                                                                <InputWithLabel
                                                                    variant="floating"
                                                                    label="Zip Code"
                                                                    inputRef={ref}
                                                                    error={errors?.billingInfo?.address?.zipCode?.message}
                                                                    inputProps={{
                                                                        ...rest,
                                                                        placeholder: "00000",
                                                                        autoComplete: "postal-code",
                                                                        value: UIUtils.maskPostalCodeUS(value),
                                                                        onChange: e => onChange(UIUtils.maskPostalCodeUS(e.target.value)),
                                                                        "aria-describedby": errors?.billingInfo?.address?.zipCode ? "error-address-zipCode" : undefined
                                                                    }}
                                                                />
                                                            </FormField>
                                                        )}
                                                    />
                                                </div>
                                                {!isNoStepVariant && (
                                                    <Controller
                                                        name="phone"
                                                        control={control}
                                                        render={({ field: { ref, value, onChange, ...rest } }) => (
                                                            <FormField error={errors?.phone?.message} errorId="error-phone">
                                                                <InputWithLabel
                                                                    variant="floating"
                                                                    label={Strings.MOBILE_PHONE}
                                                                    inputRef={ref}
                                                                    error={errors?.phone?.message}
                                                                    inputProps={{
                                                                        ...rest,
                                                                        autoComplete: "tel-national",
                                                                        placeholder: "(555) 555-5555",
                                                                        type: "tel",
                                                                        value: UIUtils.maskPhone(value),
                                                                        onChange: e => onChange(UIUtils.maskPhone(e.target.value)),
                                                                        "aria-describedby": errors?.phone ? "error-phone" : undefined
                                                                    }}
                                                                />
                                                            </FormField>
                                                        )}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </motion.div>
                                </motion.div>
                            )}
                        </AnimatePresence>

                        <Terms
                            wrapperClass={cn("max-w-[580px] hidden md:flex mt-5")}
                            handleExtraChange={handleExtraChange}
                            quoteExtra={quote.extra}
                            isQuoteUpdating={isQuoteUpdating}
                            priorityCode={priorityCode}
                        />
                    </div>
                    <PolicySummary quote={quote} breeds={breeds} wrapperClass="hidden md:flex md:self-start" config={{ isSingleCol: true }} />
                </div>
                <Terms wrapperClass="max-w-[580px] md:hidden" handleExtraChange={handleExtraChange} quoteExtra={quote.extra} isQuoteUpdating={isQuoteUpdating} />
            </div>
            {modal.render}
        </>
    );
}
