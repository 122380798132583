import { PublicConfig } from "@/shared/PublicConfig";
import Strings from "@/shared/utils/Strings.constants";

export const ContactDisclaimer = () => (
    <p className="leading-5">
        By clicking &quot;{Strings.PTZ_US.SELECT_COVERAGE_TEXT}&quot; you agree that Spot and its affiliates may contact you for marketing or informational purposes via email,
        text, or phone through the email and/or phone numbers you provided, including autodialers. You are not required to provide your phone number and your consent to these terms
        is not required to purchase pet insurance. Your information is collected and used in accordance with Spot’s{" "}
        <a href={`${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/privacy`} target="_blank" className="underline">
            Privacy Policy
        </a>{" "}
        and{" "}
        <a href={`${PublicConfig.PTZ_US.SPOT_MARKETING_URL}/privacy#texting-terms`} target="_blank" className="underline">
            Texting Terms
        </a>
        . Opt out any time.
    </p>
);
