import { Appearance, StripeElementsOptionsMode, StripeExpressCheckoutElementOptions, StripePaymentElementOptions } from "@stripe/stripe-js";
import { PublicConfig } from "@/shared/PublicConfig";

export interface StripePaymentElementError {
    stripe: boolean;
    header?: string;
    message?: string;
}

export default class StripeUtils {
    // Style the Stripe Elements with Appearance API
    // https://docs.stripe.com/elements/appearance-api
    static readonly themeOptions: Appearance = {
        theme: "stripe",
        variables: {
            borderRadius: "0.375rem",
            fontFamily: "Poppins, sans-serif",
            fontSmooth: "auto",
            gridRowSpacing: "1.25rem"
        },
        rules: {
            ".Label": {
                paddingLeft: "1rem",
                fontSize: ".75rem",
                color: "#4b5563"
            },
            ".Input": {
                paddingLeft: "1rem",
                border: "none",
                boxShadow: "0 0 0 1px #d1d5db"
            },
            ".Input:focus": {
                borderColor: "none",
                boxShadow: "0 0 0 4px rgba(0, 124, 131, 0.4)"
            },
            ".Error": {
                fontSize: ".75rem"
            }
        }
    };

    // https://docs.stripe.com/js/elements_object/create
    static getElementsOptions(amount: number): StripeElementsOptionsMode {
        const parsedAmount = parseFloat((amount * 100).toFixed(0));
        return {
            mode: "payment",
            amount: parsedAmount,
            currency: "usd",
            paymentMethodCreation: "manual",
            setupFutureUsage: "off_session", // allows future charges without customer present
            onBehalfOf: PublicConfig.PTZ_US.STRIPE_CONNECTED_ACCOUNT_ID,
            appearance: StripeUtils.themeOptions,
            fonts: [
                {
                    cssSrc: "https://fonts.googleapis.com/css2?family=Poppins:wght@400;500"
                }
            ]
        };
    }

    static readonly expressPaymentOptions: StripeExpressCheckoutElementOptions = {
        buttonType: {
            googlePay: "checkout",
            applePay: "check-out"
        },
        paymentMethods: {
            // googlePay: "never"
        }
    };

    static paymentElementOptions: StripePaymentElementOptions = {
        fields: {
            billingDetails: "never" // hides the address fields
        },
        // hides the card terms, ex: "By providing your card information, you allow Spot to charge your card..."
        terms: {
            card: "never",
            googlePay: "never",
            applePay: "never"
        },
        wallets: {
            googlePay: "never",
            applePay: "never"
        }
    };
}
